<template>
  <div class="service-detail">
    <content-block title="基本信息">
      <el-descriptions :column="3" size="normal">
        <el-descriptions-item label="销售合同编号">{{
          detailInfo.pcCode | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item label="销售订单号">{{
          detailInfo.orderCode | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item label="售后申请单号">{{
          detailInfo.afterCode | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item label="客户抬头">{{
          detailInfo.unit | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item label="客户联系人">{{
          detailInfo.salesmanName | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item label="联系电话">{{
          detailInfo.salesmanPhone | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item label="售后类型">{{
          detailInfo.typeName | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item label="售后原因">{{
          detailInfo.reason | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item label="售后申请状态">{{
          detailInfo.stateName | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item v-if="Number(returnType) !== 1" label="退款金额">{{
          detailInfo.refundAmout | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item v-if="Number(returnType) !== 1" label="收款账号">{{
          detailInfo.account | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item v-if="Number(returnType) === 1 || Number(returnType) === 3" label="退货收货信息">{{
          detailInfo.detailAddress | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item label="售后创建时间">{{
          detailInfo.createTime | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item label="备注">{{
          detailInfo.remark | setEmpty
        }}</el-descriptions-item>
      </el-descriptions>
    </content-block>
    <content-block v-if="Number(returnType) === 1 || Number(returnType) === 3" title="退货信息">
      <vxe-grid
        ref="xGrid"
        auto-resize
        resizable
        border
        align="center"
        highlight-current-row
        highlight-hover-row
        show-overflow
        show-footer
        :columns="columnList"
        :data="detailInfo.sellerAfterSerRepDtoList"
        :footer-method="footerMethod"
        :merge-footer-items="footerItems"
      >
        <template slot="empty">
          <el-empty :image-size="100" description="暂无数据"></el-empty>
        </template>
      </vxe-grid>
      <el-dialog
        title="退货商品"
        :visible.sync="visible"
        width="80%"
      >
        <table-with-pagination
          ref="xTable"
          :showRightToolbar="false"
          :columnList="refundGoodsColumns"
          :tableData="refundGoodsList"
          :height="500"
        />
        <el-row type="flex" justify="center" style="margin-top: 12px">
          <el-button type="warning" size="small" @click="visible = false">返回</el-button>
        </el-row>
      </el-dialog>
    </content-block>
    <bottom-panel v-if="type !== 'detail'">
      <label style="float: left">
        <span class="common-font">审批意见：</span>
        <el-input
          placeholder="请输入审批意见"
          v-model.trim="approveMsg"
          style="width: 500px"
          maxlength="50"
        ></el-input>
      </label>
      <template>
        <el-button
          style="margin-left: 1em"
          type="danger"
          @click="doApprove('reject')"
          >驳回</el-button
        >
        <el-button type="primary" @click="doApprove('pass')">通过</el-button>
      </template>
    </bottom-panel>

  </div>
</template>
<script>
import { mapState } from 'vuex';
import {
  editState,
  view,
  returnedMaterialList,
} from '@/api/service';
import setTableCols from './configs';
import BottomPanel from '@/components/common/bottomPanel';
import {
  executionStatusGroup,
  typeStatusOptions,
} from '@/model/service';
import * as Moment from 'dayjs';
const formateDate = (date, format) => {
  if (!date) {
    return '--';
  } else {
    return Moment(date).format(format);
  }
};

export default {
  name: 'service_detail',
  components: {
    BottomPanel,
  },
  props: {
    id: {
      type: String,
    },
    type: {
      type: String,
    },
    returnType: {
      type: String,
    },
  },
  filters: {
    setEmpty (val) {
      return val ? val : '--';
    },
  },
  computed: mapState({
    updateUserId: (state) => state.User.userInfo.userId,
    updateUserName: (state) => state.User.userInfo.name,
  }),
  data () {
    return {
      visible: false,
      approveMsg: '',
      detailInfo: {
        sellerAfterSerRepDtoList: [],
      },
      columnList: setTableCols.call(this, 'serviceDetail'),
      footerItems: [{ row: 0, col: 1, rowspan: 1, colspan: 13 }],
      refundGoodsList: [],
      refundGoodsColumns: [
        {
          prop: 'warehouseName',
          label: '入库仓库',
          tooltip: true,
        },
        {
          prop: 'systemSerialNumber',
          label: '系统序列号',
          tooltip: true,
        },
        {
          prop: 'goodsCode',
          label: '商品编码',
          tooltip: true,
        },
        {
          prop: 'goodsName',
          label: '商品名称',
          tooltip: true,
        },
        {
          prop: 'specification',
          label: '规格',
          tooltip: true,
        },
        {
          prop: 'model',
          label: '型号',
          tooltip: true,
        },
        {
          prop: 'brandName',
          label: '品牌名称',
          tooltip: true,
        },
        {
          prop: 'producer',
          label: '生产商',
        },
        {
          prop: 'factoryCode',
          label: '厂家编码',
        },
        {
          prop: 'returnAbleNum',
          label: '可退货数量',
          render: h => {
            return h('span', 1);
          },
        },
        {
          prop: 'productionDate',
          label: '生产日期',
          tooltip: true,
          format: val => formateDate(val, 'YYYY-MM-DD'),
        },
        {
          prop: 'expiryDate',
          label: '失效日期',
          tooltip: true,
          format: val => formateDate(val, 'YYYY-MM-DD'),
        },
        {
          prop: 'warehousingBatch',
          label: '入库批次',
          tooltip: true,
        },
        {
          prop: 'isMedichair',
          label: '是否医疗件',
          tooltip: true,
          format: val => (val === 1 ? '是' : val === 2 ? '否' : ''),
        },
        {
          prop: 'batchNumber',
          label: '生产批次号',
          tooltip: true,
        },
        {
          prop: 'serialNumber',
          label: '生产序列号',
          tooltip: true,
        },
        {
          prop: 'regNo',
          label: '注册证号',
          tooltip: true,
        },
      ],
    };
  },
  created () {
    this.getDetail();
  },
  methods: {
    getName (enums, val) {
      for (let v of enums) {
        if (v.value * 1 === val) {
          return v.label;
        }
      }

      return '';
    },
    getDetail () {
      view({ id: this.id }).then(({ body }) => {
        this.detailInfo = body;

        this.detailInfo.typeName = this.getName(typeStatusOptions, body.type);
        this.detailInfo.stateName = this.getName(executionStatusGroup, body.state);
        this.detailInfo.detailAddress = `${body.provinceName || ''}${body.cityName || ''}${body.areaName || ''}${body.address || ''}`;
      });
    },
    footerMethod ({ data }) {
      if (!data.length) {
        return [];
      }

      let totalAmount = 0;
      let totalPrice = 0;

      data.forEach((v) => {
        totalAmount += Number(v.currentReturnNum);
        totalPrice += Number(v.unitPrice * 100 * v.currentReturnNum);
      });

      return [['合计', `数量：${totalAmount}, 总金额：${totalPrice / 100}`]];
    },
    doApprove (type) {
      let param = {
        id: this.id,
        supIdea: this.approveMsg,
        state: type === 'reject' ? 7 : 2, // 7->已驳回 2->售后处理中
        updateUserId: this.updateUserId,
        updateUserName: this.updateUserName,
      };

      editState(param).then(() => {
        this.$message({
          message: type === 'reject' ? '售后已驳回' : '审核通过',
          type: 'success',
        });

        this.$router.back();
      });
    },
    handleReturn (row) {
      this.getRefundReturnGoods(row);
    },
    getRefundReturnGoods (row) {
      this.visible = true;

      returnedMaterialList({materialId: row.materialId, id: this.id, afterItemId: row.id}).then(({ body }) => {
        this.refundGoodsList = body || [];
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.service-detail {
  .common-font {
    color: #909399;
    font-size: 14px;
    font-weight: 500;
  }

  /deep/ .del {
    color: blue;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
